html {
  line-height: 1.15;

  /* 1 */

  -ms-text-size-adjust: 100%;

  /* 2 */

  -webkit-text-size-adjust: 100%;

  /* overflow: scroll !important; */

  /* 2 */
}

/*Start*/
/*New CSS For Version 2.0*/
/* .iUh30 {
  font-size: 16px;
  padding-top: 1px;
  line-height: 1.5;
  color: green;
  font-family: Arial, Helvetica, sans-serif !important;
  font-style: normal !important;
} */

.rank {
  padding-left: 15px;
  font-size: 12px;
}
.iUh30 {
  padding-left: 9px;
  font-size: 16px;
  padding-top: 1px;
  line-height: 1.5;
  color: green;
  font-family: Arial, Helvetica, sans-serif !important;
  font-style: normal !important;
}
.button-disappear {
  display: None;
}
.TbwUpd {
  padding-bottom: 0px;
  padding-top: 1px;
}
.ellip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thumbnail {
  margin-top: 22px;
}
/*End*/
p {
  font-weight: normal !important;
}
.search-result-heading {
  font-size: 20px;
  /* color: #1a0dab; */
  color: black !important;
}

/* Sections

   ========================================================================== */

/**

 * Remove the margin in all browsers (opinionated).

 */

body {
  margin: 0;
  overflow: scroll !important;
  /* padding-bottom: 60px; */
}

.containerPosition {
  position: relative;

  /*min-height: 100vh;*/

  padding-bottom: 2.5rem;
}

.flex-grow {
  flex: 1;
}

/**

 * Add the correct display in IE 9-.

 */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**

 * Correct the font size and margin on `h1` elements within `section` and

 * `article` contexts in Chrome, Firefox, and Safari.

 */

h1 {
  font-size: 2em;

  margin: 0.67em 0;
}

/* Grouping content

   ========================================================================== */

/**

 * Add the correct display in IE 9-.

 * 1. Add the correct display in IE.

 */

/* card :start */

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  max-width: 300px;

  margin: auto;

  text-align: center;

  font-family: arial;
}

.cardTitle {
  color: grey;

  font-size: 18px;
}

.cardButton {
  border: none;

  outline: 0;

  display: inline-block;

  padding: 8px;

  color: white;

  background-color: #2d2762;

  text-align: center;

  cursor: pointer;

  width: 100%;

  font-size: 18px;
}

.cardButton:hover,
a:hover {
  opacity: 0.7;
}

/* card end */

/* product detail css */

.product-content {
  border: 1px solid #dfe5e9;

  margin-bottom: 20px;

  margin-top: 12px;

  background: #fff;
}

.product-content .carousel-control.left {
  margin-left: 0;
}

.product-content .product-image {
  background-color: #fff;

  display: block;

  min-height: 238px;

  overflow: hidden;

  position: relative;
}

.product-content .product-deatil {
  border-bottom: 1px solid #dfe5e9;

  padding-bottom: 17px;

  padding-left: 16px;

  padding-top: 16px;

  position: relative;

  background: #fff;
}

.product-content .product-deatil h5 a {
  color: #2f383d;

  font-size: 15px;

  line-height: 19px;

  text-decoration: none;

  padding-left: 0;

  margin-left: 0;
}

.product-content .product-deatil h5 a span {
  color: #9aa7af;

  display: block;

  font-size: 13px;
}

.product-content .product-deatil span.tag1 {
  border-radius: 50%;

  color: #fff;

  font-size: 15px;

  height: 50px;

  padding: 13px 0;

  position: absolute;

  right: 10px;

  text-align: center;

  top: 10px;

  width: 50px;
}

.product-content .product-deatil span.sale {
  background-color: #21c2f8;
}

.product-content .product-deatil span.discount {
  background-color: #71e134;
}

.product-content .product-deatil span.hot {
  background-color: #fa9442;
}

/* .product detail css end */

figcaption,
figure,
main {
  /* 1 */

  display: block;
}

.fixed-bottom {
  position: fixed;

  right: 0;

  bottom: 0;

  left: 0;

  z-index: 1030;
}

.footer {
  margin-top: 50px;

  background-color: #2d2762;

  color: white;

  width: 100%;

  bottom: 0;

  left: 0;

  right: 0;

  position: absolute;

  /* margin-bottom: -210px; */
  margin-bottom: -70px;
  height: 50px;
}

@media (max-width: 500px) {
  .footer {
    margin-top: 50px;

    background-color: #a9a9a9;

    color: white;

    width: 100%;

    bottom: 0;

    left: 0;

    right: 0;

    position: absolute;

    margin-bottom: -370px;

    height: 50px;
  }
}

/**

 * Add the correct margin in IE 8.

 */

figure {
  margin: 1em 40px;
}

/**

 * 1. Add the correct box sizing in Firefox.

 * 2. Show the overflow in Edge and IE.

 */

hr {
  box-sizing: content-box;

  /* 1 */

  height: 0;

  /* 1 */

  overflow: visible;

  /* 2 */
}

/**

 * 1. Correct the inheritance and scaling of font size in all browsers.

 * 2. Correct the odd `em` font sizing in all browsers.

 */

pre {
  font-family: monospace, monospace;

  /* 1 */

  font-size: 1em;

  /* 2 */
}

/* Text-level semantics

   ========================================================================== */

/**

 * 1. Remove the gray background on active links in IE 10.

 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.

 */

a {
  background-color: transparent;

  /* 1 */

  -webkit-text-decoration-skip: objects;

  /* 2 */
}

/**

 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.

 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.

 */

abbr[title] {
  border-bottom: none;

  /* 1 */

  text-decoration: underline;

  /* 2 */

  text-decoration: underline dotted;

  /* 2 */
}

/**

 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.

 */

b,
strong {
  font-weight: inherit;
}

/**

 * Add the correct font weight in Chrome, Edge, and Safari.

 */

b,
strong {
  font-weight: bolder;
}

/**

 * 1. Correct the inheritance and scaling of font size in all browsers.

 * 2. Correct the odd `em` font sizing in all browsers.

 */

code,
kbd,
samp {
  font-family: monospace, monospace;

  /* 1 */

  font-size: 1em;

  /* 2 */
}

/**

 * Add the correct font style in Android 4.3-.

 */

dfn {
  font-style: italic;
}

/**

 * Add the correct background and color in IE 9-.

 */

mark {
  background-color: #ff0;

  color: #000;
}

/**

 * Add the correct font size in all browsers.

 */

small {
  font-size: 80%;
}

/**

 * Prevent `sub` and `sup` elements from affecting the line height in

 * all browsers.

 */

sub,
sup {
  font-size: 75%;

  line-height: 0;

  position: relative;

  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content

   ========================================================================== */

/**

 * Add the correct display in IE 9-.

 */

audio,
video {
  display: inline-block;
}

/**

 * Add the correct display in iOS 4-7.

 */

audio:not([controls]) {
  display: none;

  height: 0;
}

/**

 * Remove the border on images inside links in IE 10-.

 */

img {
  border-style: none;
}

/**

 * Hide the overflow in IE.

 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms

   ========================================================================== */

/**

 * 1. Change the font styles in all browsers (opinionated).

 * 2. Remove the margin in Firefox and Safari.

 */

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;

  /* 1 */

  font-size: 100%;

  /* 1 */

  line-height: 1.15;

  /* 1 */

  margin: 0;

  /* 2 */
}

/**

 * Show the overflow in IE.

 * 1. Show the overflow in Edge.

 */

button,
input {
  /* 1 */

  overflow: visible;
}
button,
input1 {
  /* 1 */

  overflow: visible;

}

/**

 * Remove the inheritance of text transform in Edge, Firefox, and IE.

 * 1. Remove the inheritance of text transform in Firefox.

 */

button,
select {
  /* 1 */

  text-transform: none;
}

/**

 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`

 *    controls in Android 4.

 * 2. Correct the inability to style clickable types in iOS and Safari.

 */

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  /* 2 */
}

/**

 * Remove the inner border and padding in Firefox.

 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;

  padding: 0;
}

/**

 * Restore the focus styles unset by the previous rule.

 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**

 * Correct the padding in Firefox.

 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**

 * 1. Correct the text wrapping in Edge and IE.

 * 2. Correct the color inheritance from `fieldset` elements in IE.

 * 3. Remove the padding so developers are not caught out when they zero out

 *    `fieldset` elements in all browsers.

 */

legend {
  box-sizing: border-box;

  /* 1 */

  color: inherit;

  /* 2 */

  display: table;

  /* 1 */

  max-width: 100%;

  /* 1 */

  padding: 0;

  /* 3 */

  white-space: normal;

  /* 1 */
}

/**

 * 1. Add the correct display in IE 9-.

 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.

 */

progress {
  display: inline-block;

  /* 1 */

  vertical-align: baseline;

  /* 2 */
}

/**

 * Remove the default vertical scrollbar in IE.

 */

textarea {
  overflow: auto;
}

/**

 * 1. Add the correct box sizing in IE 10-.

 * 2. Remove the padding in IE 10-.

 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;

  /* 1 */

  padding: 0;

  /* 2 */
}

/**

 * Correct the cursor style of increment and decrement buttons in Chrome.

 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**

 * 1. Correct the odd appearance in Chrome and Safari.

 * 2. Correct the outline style in Safari.

 */

[type="search"] {
  -webkit-appearance: textfield;

  /* 1 */

  outline-offset: -2px;

  /* 2 */
}

/**

 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.

 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**

 * 1. Correct the inability to style clickable types in iOS and Safari.

 * 2. Change font properties to `inherit` in Safari.

 */

::-webkit-file-upload-button {
  -webkit-appearance: button;

  /* 1 */

  font: inherit;

  /* 2 */
}

/* Interactive

   ========================================================================== */

/*

 * Add the correct display in IE 9-.

 * 1. Add the correct display in Edge, IE, and Firefox.

 */

details,
menu {
  display: block;
}

/*

 * Add the correct display in all browsers.

 */

summary {
  display: list-item;
}

/* Scripting

   ========================================================================== */

/**

 * Add the correct display in IE 9-.

 */

canvas {
  display: inline-block;
}

/**

 * Add the correct display in IE.

 */

template {
  display: none;
}

/* Hidden

   ========================================================================== */

/**

 * Add the correct display in IE 10-.

 */

[hidden] {
  display: none;
}

html {
  height: 100%;
}

fieldset {
  margin: 0;

  padding: 0;

  -webkit-margin-start: 0;

  -webkit-margin-end: 0;

  -webkit-padding-before: 0;

  -webkit-padding-start: 0;

  -webkit-padding-end: 0;

  -webkit-padding-after: 0;

  border: 0;
}

legend {
  margin: 0;

  padding: 0;

  display: block;

  -webkit-padding-start: 0;

  -webkit-padding-end: 0;
}

/*===============================

=            Choices            =

===============================*/

.choices {
  position: relative;

  margin-bottom: 24px;

  font-size: 16px;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.navbar-inverse {
  background-color: #2d2762 !important;
}

.landing-page-logo {
  padding-left: 215px !important;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;

  cursor: not-allowed;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  display: block;

  width: 100%;

  padding: 10px;

  border-bottom: 1px solid #dddddd;

  background-color: #ffffff;

  margin: 0;
}

.choices[data-type*="select-one"] .choices__button {
  /* background-image: url("../../icons/cross-inverse.svg"); */

  padding: 0;

  background-size: 8px;

  height: 100%;

  position: absolute;

  top: 50%;

  right: 0;

  margin-top: -10px;

  margin-right: 25px;

  height: 20px;

  width: 20px;

  border-radius: 10em;

  opacity: 0.5;
}

.choices[data-type*="select-one"] .choices__button:hover,
.choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00bcd4;
}

.choices[data-type*="select-one"]:after {
  content: "";

  height: 0;

  width: 0;

  border-style: solid;

  border-color: #333333 transparent transparent transparent;

  border-width: 5px;

  position: absolute;

  right: 11.5px;

  top: 50%;

  margin-top: -2.5px;

  pointer-events: none;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #333333 transparent;

  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;

  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;

  left: 0;

  margin-left: 25px;

  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;

  display: inline-block;

  margin-top: 0;

  margin-right: -4px;

  margin-bottom: 0;

  margin-left: 8px;

  padding-left: 16px;

  border-left: 1px solid #008fa1;

  /* background-image: url("../../icons/cross.svg"); */

  background-size: 8px;

  width: 8px;

  line-height: 1;

  opacity: 0.75;
}

.choices[data-type*="select-multiple"] .choices__button:hover,
.choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;

  vertical-align: top;

  width: 100%;

  background-color: #f9f9f9;

  padding: 7.5px 7.5px 3.75px;

  border: 1px solid #dddddd;

  border-radius: 2.5px;

  font-size: 14px;

  min-height: 44px;

  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;

  padding-left: 0;

  list-style: none;
}

.choices__list--single {
  display: inline-block;

  padding: 4px 16px 4px 4px;

  width: 100%;
}

[dir="rtl"] .choices__list--single {
  padding-right: 4px;

  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;

  vertical-align: middle;

  border-radius: 20px;

  padding: 4px 10px;

  font-size: 12px;

  font-weight: 500;

  margin-right: 3.75px;

  margin-bottom: 3.75px;

  background-color: #00bcd4;

  border: 1px solid #00a5bb;

  color: #ffffff;

  word-break: break-all;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;

  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;

  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;

  border: 1px solid #919191;
}

.choices__list--dropdown {
  display: none;

  z-index: 1;

  position: absolute;

  width: 100%;

  background-color: #ffffff;

  border: 1px solid #dddddd;

  top: 100%;

  margin-top: -1px;

  border-bottom-left-radius: 2.5px;

  border-bottom-right-radius: 2.5px;

  overflow: hidden;

  word-break: break-all;
}

.choices__list--dropdown.is-active {
  display: block;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;

  bottom: 100%;

  margin-top: 0;

  margin-bottom: -1px;

  border-radius: 0.25rem 0.25rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;

  max-height: 300px;

  overflow: auto;

  -webkit-overflow-scrolling: touch;

  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;

  padding: 10px;

  font-size: 14px;
}

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }

  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);

    font-size: 12px;

    opacity: 0;

    position: absolute;

    right: 10px;

    top: 50%;

    transform: translateY(-50%);
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;

    padding-left: 100px;

    padding-right: 10px;
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;

    left: 10px;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;

  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;

  font-size: 12px;

  padding: 10px;

  border-bottom: 1px solid #f7f7f7;

  color: gray;
}

.choices__button {
  text-indent: -9999px;

  -webkit-appearance: none;

  -moz-appearance: none;

  appearance: none;

  border: 0;

  background-color: transparent;

  background-repeat: no-repeat;

  background-position: center;

  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;

  vertical-align: baseline;

  background-color: #f9f9f9;

  font-size: 14px;

  margin-bottom: 5px;

  border: 0;

  border-radius: 0;

  max-width: 100%;

  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir="rtl"] .choices__input {
  padding-right: 2px;

  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

/*=====  End of Choices  ======*/

input,
button {
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}

.s130 {
  /* min-height: 20vh; */

  display: -ms-flexbox;

  display: flex;

  -ms-flex-pack: center;

  justify-content: center;

  font-family: "Poppins", sans-serif;

  /* background: url("../images/doctors2.jpg"); */

  background-size: cover;

  background-attachment: fixed;

  /* background-position: bottom right; */

  background-repeat: no-repeat;

  /* background-size: 100%; */

  padding: 15px;

  /* margin: -20px; */
}

.s130 form {
  width: 100%;

  max-width: 790px;

  padding-top: 0vh;

  height: 100%;
}

.btn-primary1 {
  background-color: #2d2762 !important;

  color: white;

  width: 100%;
}

.btn-primary1:hover {
  background: #f45b69 !important;
}

.s130 form .inner-form {
  display: -ms-flexbox;

  display: flex;

  width: 100%;

  -ms-flex-pack: justify;

  justify-content: space-between;

  -ms-flex-align: center;

  align-items: center;

  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);

  border-radius: 200px 34px;

  overflow: hidden;

  /* margin-bottom: 30px; */

  /* margin-top: 27px; */

}
.homesearchbar{
  margin: 7% 0px;
}

.resultSearchBar{
  margin-top: 3%;
}

/* Togglebuttons */

.btn-success1 {
  color: #fff;

  background-color: #2d2762;

  border-color: #2d2762;

  width: 80%;

  height: 33px;
}

.btnfocus1 {
  color: #fff;

  display: inline-block;

  margin-bottom: 0;

  font-weight: 400;

  text-align: center;

  white-space: nowrap;

  vertical-align: middle;

  -ms-touch-action: manipulation;

  touch-action: manipulation;

  cursor: pointer;

  background-image: none;

  border: 1px solid transparent;

  padding: 6px 12px;

  font-size: 14px;

  line-height: 1.42857143;

  border-radius: 50px;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.btn-success1:hover {
  background: #f45b69;
}

.dropdownfocus {
  position: absolute;

  /* transform: translate3d(30px, 33px, 0px) !important; */

  top: 0px;

  left: 0px;

  will-change: transform;

  background-color: transparent !important;

  text-align: center;
}

/* .dropdownfocus:hover {

  background: #f45b69;

} */

.dropdown-menu1 {
  position: absolute;

  top: 100%;

  left: 0;

  z-index: 1000;

  display: none;

  float: left;

  min-width: 160px;

  padding: 25px 75px !important;

  margin: 2px 0 0;

  font-size: 14px;

  /* text-align: left; */

  list-style: none;

  background-color: #fff;

  background-clip: padding-box;

  border: 1px solid #ccc;

  border: 1px solid rgba(0, 0, 0, 0.15);

  border-radius: 20px !important;

  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

/* .col1-sm1-12 {

  padding-top: 25px !important;

} */

.p1 {
  margin: 70px 0 10px;

  margin-left: -1000;
}

/* .col-lg-12 {

  margin-left: -1000px !important;

  padding-top: 150px;

} */

* {
  margin: 0;

  padding: 0;

  box-sizing: border-box;

  font-family: "Open Sans Condensed", sans-serif;

  /* font-weight: 700; */
}

html,
body {
  background: #fff;
}

header {
  width: 95%;

  display: flex;

  justify-content: flex-end;

  flex-flow: row wrap;
}

header ul {
  padding: 1.2em 0 0.5em;
}

header span {
  padding: 0 0 0 0.5em;
}

header span,
header i {
  color: #2b2d42;
}

header i:nth-of-type(2) {
  cursor: pointer;
}

.row {
  width: 100%;

  justify-content: flex-start;

  display: flex;

  flex-flow: row wrap;

  position: relative;
}

.product,
.product--blue,
.product--orange,
.product--red,
.product--green,
.product--yellow,
.product--pink {
  box-shadow: 1px 5px 15px #ccc;

  width: 13em;

  height: auto;

  border-radius: 3px;

  padding: 0em;

  margin: 0.5em;

  overflow: hidden;

  position: relative;

  flex: auto;
}

.product--blue {
  background: linear-gradient(
    -45deg,
    #2d2762 50%,
    rgba(255, 255, 255, 0.5) 50%
  );
}

.product--orange {
  background: linear-gradient(
    -45deg,
    #2d2762 50%,
    rgba(255, 255, 255, 0.5) 50%
  );
}

.product--red {
  background: linear-gradient(-45deg, #2d2762 50%, #eeeeee 50%);

  /* rgba(255, 255, 255, 0.5) 50% */
}

.product--green {
  background: linear-gradient(
    -45deg,
    #2d2762 50%,
    rgba(255, 255, 255, 0.5) 50%
  );
}

.product--yellow {
  background: linear-gradient(
    -45deg,
    #2d2762 50%,
    rgba(255, 255, 255, 0.5) 50%
  );
}

.product--pink {
  background: linear-gradient(
    -45deg,
    #2d2762 50%,
    rgba(255, 255, 255, 0.5) 50%
  );
}

.product img,
.product--blue img,
.product--orange img,
.product--red img,
.product--green img,
.product--yellow img,
.product--pink img {
  max-width: 100%;

  height: auto !important;

  text-align: center;
}

.product_inner {
  display: flex;

  align-items: center;

  flex-flow: column wrap;
}

.product_inner p {
  color: rgba(255, 255, 255, 0.9);
}

.product_inner button {
  border: 1px solid rgba(255, 255, 255, 0.5);

  color: #fff;

  border-radius: 3px;

  padding: 1em 3em;

  margin: 1em 0 0 0;

  background: none;

  cursor: pointer;

  transition: background ease-in 0.25s;
}

.product_inner button:hover {
  background: #f45b69;

  color: #2b2d42;
}

/* .product_inner button:before {

  font-family: FontAwesome;

  content: "\f07A";

  color: #fff;

  position: absolute;

  font-size: 1.5em;

  margin: 0 -1.5em;

} */

.product_inner button:hover:before {
  color: #2b2d42;
}

.product_overlay {
  background: rgba(255, 255, 255, 0.9);

  width: 100%;

  height: 100%;

  position: absolute;

  top: 0;

  left: 0;

  right: 0;

  -webkit-transform: translateY(-500px);

  transform: translateY(-500px);

  opacity: 0;

  display: flex;

  flex-flow: column wrap;

  justify-content: center;

  align-items: center;
}

.product_overlay h2 {
  color: rgba(43, 45, 66, 0.7);

  font-size: 1.2em;

  margin: 1em 0;
}

.product_overlay i {
  color: rgba(43, 45, 66, 0.7);

  font-size: 1.5em;
}

/* TOgglebuttonsEnd */

.s130 form .inner-form .input-field {
  height: 68px;
}

.s130 form .inner-form .input-field input {
  height: 100%;

  background: transparent;

  border: 0;

  display: block;

  width: 100%;

  padding: 10px 0;

  font-size: 16px;

  color: #000;
}

.s130 form .inner-form .input-field input.placeholder {
  color: #222;

  font-size: 16px;
}

.s130 form .inner-form .input-field input:-moz-placeholder {
  color: #222;

  font-size: 16px;
}

.s130 form .inner-form .input-field input::-webkit-input-placeholder {
  color: #222;

  font-size: 16px;
}

.s130 form .inner-form .input-field input:hover,
.s130 form .inner-form .input-field input:focus {
  box-shadow: none;

  outline: 0;
}

.s130 form .inner-form .input-field.first-wrap {
  -ms-flex-positive: 1;

  flex-grow: 1;

  display: -ms-flexbox;

  display: flex;

  -ms-flex-align: center;

  align-items: center;

  background: #eeeeee;
}

.s130 form .inner-form .input-field.first-wrap input {
  -ms-flex-positive: 1;

  flex-grow: 1;
}

.s130 form .inner-form .input-field.first-wrap .svg-wrapper {
  min-width: 80px;

  display: -ms-flexbox;

  display: flex;

  -ms-flex-pack: center;

  justify-content: center;

  -ms-flex-align: center;

  align-items: center;
}

.s130 form .inner-form .input-field.first-wrap svg {
  width: 36px;

  height: 36px;

  fill: #222;
}

.s130 form .inner-form .input-field.second-wrap {
  min-width: 216px;
}

.s130 form .inner-form .input-field.second-wrap .btn-search {
  height: 100%;

  width: 100%;

  white-space: nowrap;

  font-size: 16px;

  color: #fff;

  border: 0;

  cursor: pointer;

  position: relative;

  z-index: 0;

  background: #2d2762;

  transition: all 0.2s ease-out, color 0.2s ease-out;

  font-weight: 300;
}

.s130 form .inner-form .input-field.second-wrap .btn-search:hover {
  background: #f45b69;
}

.s130 form .inner-form .input-field.second-wrap .btn-search:focus {
  outline: 0;

  box-shadow: none;
}

.s130 form .info {
  font-size: 15px;

  color: #ccc;

  padding-left: 26px;
}

@media screen and (max-width: 992px) {
  .s130 form .inner-form .input-field {
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .s130 form .inner-form .input-field.first-wrap .svg-wrapper {
    min-width: 40px;

    display: -ms-flexbox;

    display: flex;

    -ms-flex-pack: center;

    justify-content: center;

    -ms-flex-align: center;

    align-items: center;

    padding: 0 10px;
  }

  .s130 form .inner-form .input-field.first-wrap svg {
    width: 26px;

    height: 26px;

    fill: #222;
  }

  .s130 form .inner-form .input-field.second-wrap {
    min-width: 100px;
  }

  .s130 form .inner-form .input-field.second-wrap .btn-search {
    font-size: 13px;
  }
}

/*# sourceMappingURL=Searchs_130.css.map */

/* NewSearchChanges - CSS */

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-width: 1px 0px;
}

.search-form {
  margin-top: 10px;
}

.search-result h3 {
  margin-bottom: 0;
  color: #1e0fbe;
}

.search-result .search-link {
  color: #006621;
}

.search-result p {
  font-size: 12px;
  margin-top: 5px;
}

.hr-line-dashed {
  border-top: 1px dashed #e7eaec;
  color: #ffffff;
  background-color: #ffffff;
  height: 1px;
  margin: 20px 0;
}

h2 {
  font-size: 24px;
  font-weight: 100;
}

.p1 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boxResult {
  /* background-color: #2d2762 !important; */
  width: 1000px;
  height: 30%;
  border: 3px solid black;
  padding: 50px;
  margin: 20px;
  left: 25%;
  top: 50%;
  position: absolute;
}

.card {
  max-width: 100% !important;
  margin-top: 0px !important;
}

.heading {
  color: #1a0dab;
}
.f20{
  font-size:20px !important;
  font-weight:700 !important;
}

.heading:hover {
  color: #337ab7;
}
.seemore{
  border:1px solid #ccc;
  color:#666;
  padding:3px;
  margin:4px;
  font-size:13px;
}
.seemore:hover{
  text-decoration:none;
}
.contactCard{
  margin: 24px 0;
}
.contactCard a {
  margin:0px 3px;
}
.fullWidth{
  width:100%
}
.noBottomMargin{
  margin-bottom:0px !important;
}
.messageForm textarea{
  height:120px !important;
  border-top:0px;
  border-left:0px;
  border-radius: 0px;
}
.messageForm1 textarea{
  height:120px !important;
}
.companyCard{
  margin-top:10px !important;
}
.noMargin{
  margin:0px !important;
}
.seemore{
  border:1px solid #ccc;
  color:#666;
  padding:3px;
  margin:4px;
  font-size:13px;
  background:none;
  border-radius:0px !important;
}
.seemore:hover{
  text-decoration:none;
  background:none !important;
  border:1px solid #ccc;
  color:#666;
}
.labelTitle{
  margin-top: 15px;
  margin-left: 5px;
}
.profileContainer{
  min-height:500px;
}

#academicTitle,#specialistTitle,#doctorNetworkId{
    width:100%;
    padding:7px 0px 7px 7px;
    border :1px solid #e4e7ea;
}

.chatroom{
  box-shadow:0px 0px 10px #ccc;
  border-radius:10px;
  height:600px;
  overflow:hidden;
}
.chatroom div{
  padding:0px;
  margin:0px;
}

.chatroom .input{
    width:100%;
    padding:10px;
    border:none;
}
.chatroom .input:focus {
  outline: none;
}
.chatroom .sendBtn{
  background:#0782c6;
  color:#fff;
  margin-top:20px;
  margin-left:2px;
  border:0px;
  border-radius:20px
}
.chatroom .sendBtn i{
  margin-left:10px;
  border:0px;
  padding-right:10px;

}
.chatSpace{
  height:450px;
  background: #fdfdfd;
  overflow:auto;
}
.chatcontrols{
  height:90px;
  padding-top:10px;
}
.specialists{
  background:#2d2762;
  overflow:auto;
  height:600px;
}
.specialists div{
  width:90%;
  max-width:90%;
  margin:10px 0px 10px 20px;
}
.specialists div a{
  width:100%;
  color:#fff !important;
  margin-left:20px;
  font-size:12px;
  cursor:pointer;
}

.specialists div.active{
  color:#fff !important;
  margin-left:20px;
  font-size:12px;
}

.header{
  width:90%;
  color:#fff;
  font-weight: bolder;
  font-size:18px;
  padding-left:20px;
  border-bottom:1px solid #fdfdfd;
}
.header div.img{
    box-shadow:0px 0px 10px #fff;
    height:80px;
    width:80px;
    text-align: center;
    border-radius: 80px;
    float: left;
}

.header div.img img{
  margin-top:25px;
}
.header p{
  margin-left:120px;
  height:110px;
  padding-top: 40px;
  line-height:18px;
}

.align-center{
  text-align:center;
}
.rline{
  border-right:1px solid #ededed;
}
.bline{
  border-bottom:1px solid #ededed;
}
.tline{
  border-top:1px solid #ededed;
}
.lline{
  border-left:1px solid #ededed;
}
.line{
  border:1px solid #ededed;
}


.chatroom .activeTab{
  background: #0b78bd;
  border-radius: 10px;
  padding:10px;

}
.chatroom .activeTab a{
  color:#ffff !important;
  font-weight:bolder;
}

.chatHeading div.img{
  border:1px solid #ededed;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 60px;
  float: left;
  margin:10px;
  overflow:hidden;



}
.chatHeading{
  background: #2d2762;


}
.chatHeading div.img img{
  height: 30px;
  width: 30px;
  margin:10px;



}
.chatHeading p h3{
  font-weight: bolder;
  font-size:16px;
  line-height: 16px;
  color:#fff;


}
.chatHeading p{
  line-height: 6px;
  padding:0px 0px;
  margin:25px 0px;
  color:#fff;


}
.chatHeading p small{
  font-size:12px;
  margin-top: 2px;

}

.doctors div.img{
  width:40px;
  height:40px;
  border-radius:40px;
  overflow:hidden;
  border:1px solid #ccc;
  margin:10px;
  float:left;
  text-align:center;
  line-height:35px;
}

.doctors div.img img{
  width:20px;
}

.doctors{
  font-size:12px;
  border-bottom:1px solid #ededed;
  padding:5px !important;
}

.doctors div.label{
  margin-top: 15px;
  color:#666;
  font-size: 10px;
}
.doctors div.label a{
  margin-top: 15px;
  color:#222;
  font-weight: bold;
  font-size: 14px;
}
.doctorList{
  overflow:auto;
  height:600px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
/* .doctorList::-webkit-scrollbar,.specialists::-webkit-scrollbar {
  display: none;
} */

.chatbox{
}

.chatbox div.chat{
  border-radius: 4px;
  border:1px solid #666;
  padding:10px;
}

.chat{
  border-radius: 4px;
  border:1px solid #666;
  padding:10px;
}

.chatbox div.img{
  width:40px;
  height:40px;
  border-radius:40px;
  overflow:hidden;
  border:1px solid #ccc;
  margin:10px;
  float:left;
  text-align:center;
  line-height:35px;
}

.box1 {
  width: 300px;
  margin: 50px auto;
  border: 4px solid #00bfb6;
  padding: 20px;
  text-align: center;
  font-weight: 900;
  color: #00bfb6;
  font-family: arial;
  position: relative;
}

.chatbox div.img img{
  width:20px;
}

.search-result-tabs{
  margin:20px 0px;
  border-bottom:1px solid #ededed;
}

.overview div{
  margin-bottom: 20px;
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.t-line{
  border-bottom:1px solid #ccc;
}

.chatter-box{
  border-bottom:1px solid #ededed;
  padding:10px !important;
  margin:0px 20px !important;
  width:70%;
  background: #ccc;
}

.chat_message p{
  margin:0px 0px 0px 0px;
}
.chat_message strong{
  margin:10px 0px 0px 0px;
  color:rgb(1, 59, 121);
  font-size: 12px;
  font-weight: bolder;
}
.chat_message small{
  color:rgb(1, 59, 121);
  font-size:10px;
  margin-bottom:10px;
  font-weight: bolder;
}

.filterChat{
  background: none;
  color:#333;
  font-size: 12px;
  font-weight: bold;
  border:none;
}

.searchSelect{
  height:32px;
  border:1px solid #ccc;
  width:100%;
}

.searchInput{
  height:32px;
  border:1px solid #ccc;
  width:90%;
  padding:0px 10px;
}

.searchDiv{
  padding:15px !important;
  background: #ededed;
}

.pad10{
  margin-right:20px !important;
}
.hideBtn{
  color:#333;
  text-decoration: none;
  font-weight: bold;
  margin-left:20px;
}

.txt-white{
  color:#fff !important;
}

.txt-white:hover{
  text-decoration: none;
}

.inputText{
  height:36px;
  border:1px solid #ccc;
  padding:0px 10px;
}

.btnNoShade{
  background: none !important;
  border:none;
  font-weight: bolder;
  font-size: 24px;
  padding:0px;
}

.permsTable tr td{
  border:1px solid #ccc;
  padding:10px;
}

.chatsearchbox{
  width:90%;
  margin-left:5%;
  height:32px;
  border-radius:20px;
  border:1px solid #999;
  background: #ededed;
  padding:0px 35px;
}
.mychatheading{
  margin-left: 15px;
  margin-top:10px;
  font-weight:bolder;
}

.speech-bubble1 {
	position: relative;
	background: #ccc;
	border-radius: .4em;
}


.speech-bubble {
	position: sticky;
	background: #ccc;
	border-radius: .4em;
}


.chatInput{
  border-radius: 30px;
  height:40px;
  margin-top: 10px;
  margin:14px 10px;
  width:98% !important;
}

.searchIcon{

}

.r-align{
  text-align:right;
}
.l-align{
  text-align:left;
}

.trial{
  align-text: right;
  padding: 2rem 2rem 2rem 2rem;
  float: right;
  width: 30%;
  align-items: right;
  margin: 0px 0px 0px auto;}


  .demobutton {
    border-radius: 22.22px ;
    height: 44.44px ;
    width: 44.44px ;
    font-size: 1.4em ;
    background:#2d2762;
    color: whitesmoke;
    border-style: solid;
    border-color: rgb(255, 252, 252);



  }

  .demobutton1 {
    border-radius: 28px ;
    height: 50px ;
    width: 50px ;
    font-size: 1.3em ;
    background:#2d2762;
    color: whitesmoke;
    border-style: solid;
    border-color: rgb(255, 252, 252);

  }
  .demobutton2  {
    border-radius: 26px ;
    height: 46px ;
    width: 46px ;
    font-size: 1.5em ;
    background:#2d2762;
    color: whitesmoke;
    border-style: solid;
    border-color: #f8f8fa;
    text-align: center;
    background-position: center;
    margin-left:2px;




  }

  .demobutton3 {
    border-radius: 26px ;
    height: 46px ;
    width: 46px ;
    font-size: 1.7em ;
    background:#c1c1c1;
    color: whitesmoke;
    border-style: solid;
    border-color: #f6f6f8;
  }

  .demobuttonSmall  {
    border-radius:5px ;
    height: 10px ;
    width: 10px ;
    font-size: 2em ;
    background:#2d2762;
   color: whitesmoke;
    border-color: #2d2762;
    border-style: solid;
    border-color:#2d2762;
    display: inline-block;
      cursor: pointer;
  }

  .chatroom .sendBtn1{
    background:#211b5c;
    color:#fff;
    margin-top:20px;
    margin-left:2px;
    border:0.5px;
    border-color: white;
    border-radius:20px
  }

  .picker-container {
    position: relative;
    width: 700px;
    border-radius: 25px;
    height: 44;


  }

  .emoji-icon {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 75px;

  }

  .input-style {
    padding: 7px 10px 7px 5px;
    width: calc(100% - 50px);
    height: calc(100% - 10px);
    border-radius: 30px;
    border:20px solid #ccc;
    margin-bottom: 10px;
  }

  .rdt_TableCell > div{
    white-space: normal !important;
  }

  .response-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    font-size: 24px; /* Larger font size */
  }
  
  .response-text {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
  }

  .source-list {
    max-width: 80%;
    margin: 0 auto;
    font-size: 12px;
  }
  
  .response-source {
    max-width: 80%;
    margin: 0 auto;
    font-size: 12px;
  }