.chatroomContainer {
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  height: 75vh;
  overflow: hidden;
}

.chatroomContainer div {
  margin: 0px;
  padding: 0px;
}

.chatroomContainer .activeTab {
  background: #0b78bd;
  border-radius: 10px;
  padding: 10px;
}

.chatroomContainer .activeTab a {
  color: #ffff !important;
  font-weight: bolder;
}

/*left part of the chatroom*/
.specialists {
  background: #2d2762;
  overflow: auto;
  height: 100%;
  border: 2px solid rgb(204, 204, 204);
}

.specialists div {
  width: 100%;
  margin: 15px;
}

.specialists div a {
  width: 100%;
  color: #fff !important;
  margin-left: 20px;
  font-size: 12px;
  cursor: pointer;
}

.specialists div.active {
  color: #fff !important;
  margin-left: 20px;
  font-size: 12px;
}

/*center part of the chatroom*/
.chatselection {
  height: 100%
}

.chatGroupsContainer {
  height: 50%;
  overflow: auto;
  border: 2px solid rgb(204, 204, 204);
}

.chatGroupButton {
  border-radius: 28px;
  height: 50px;
  width: 50px;
  font-size: 1.3em;
  background: #2d2762;
  color: whitesmoke;
  border-style: solid;
  border-color: rgb(255, 252, 252);
}

.chatGroupEntry {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chatGroupEntryImageContainer {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #ccc;
  margin: 10px !important;
}

.chatGroupEntryImage {
  height: 30px;
  width: auto;
}

.chatGroupEntryTitle {
  color: #222;
  font-weight: bold;
  font-size: 14px;
}

.chatGroupEntryLabel {
  color: #666;
  font-size: 10px;
}

.chatGroupEntryNotificationContainer {
  position: absolute;
  right: 0px;
}

.privateChats {
  height: 50%;
  overflow: auto;
  border: 2px solid rgb(204, 204, 204);
}

/*right part of the chatroom*/
.chatArea {
  height: 100%;
  overflow: auto;
  border: 2px solid rgb(204, 204, 204);
}

.chatAreaHeader {
  height: 10%;
  display: flex;
  align-items: center;
  background: #2d2762;
  color: white;
}

.chatAreaButtonCol {
  display: flex;
  justify-content: flex-end;
}

.chatAreaButton {
  font-size: 23px;
  margin: 10px;
}

.chatEntryArea {
  height: 80%;
  overflow: auto;
}

.chatWriteArea {
  height: 10%;
  background: #ccc;
}

.chatTextInputArea {
  display: flex;
  align-items: center;
}

.chatTextInput {
  height: 60%;
  width: 100%;
  padding: 10px;
  margin-left: 10px;
  border-radius: 10px;
  border: none;
}

.emoji-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.chatSearch {
  background-color: #e6e4e4;
  border: none;
  border-radius: 25px;
  width: 11vw;
  height: 2vw;
  margin-top: 10px;
  border-bottom: none;
  padding: 0px 10px;
  text-align: center;

}

.chatSearch:focus {
  outline: none;
  /* to remove the default focus border */
  border-bottom: none;
  /* to remove the black line */
}

.searchBox {
  display: flex;
  align-items: center;
  position: relative;
}


.searchIcon {
  position: absolute;
  display: flex;
  top: 60%;
  right: 15px;
  transform: translateY(-50%);

}

.chatInputButtonArea {
  display: flex;
  justify-content: center;
  align-items: center;
}